import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import SoftwareSec from "../components/training-webinars/training-webinars"
import Hero from "../components/events/awards-hero.js"
import BillboardImg from "../images/awards/billboard.svg"
import ElevatorImg from "../images/awards/elevator.svg"
import MovingMediaImg from "../images/awards/moving-media.svg"
import TransitShelterImg from "../images/awards/transit-shelter.svg"
import OperatorsImg from "../images/awards/operators.jpg"
import AgencyImg from "../images/awards/agencies-advertisers.jpg"
import programmaticImg from "../images/awards/programmatic.jpg"
import GoldCOMMBIcon from "../images/awards/gold-anchor.svg"
import AwardsModal from "../components/awards/modal.js"

const Awards = () => (
  <div className="eventsPage membersZone awards">
    <Layout>
      <Seo title=" Awards | COMMB" />
      <div className="contactSecWrapper awards2023Page">
        <Hero />
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/3xAcXOF7njqboXcrWFFIF6/8e561c80b11af545b3e81e4e141b9845/deco_line_divider.png" className="artDecoDivider" />
        <div className="awardWinners">
          <h2>2023 Winners by Creative Category</h2>
          
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Creative Concept</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/0ppSXHFaZ44" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/NaRgJKECvIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Use of Multi-Media</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/GB-ExJFyU28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/gCqnAewLvzM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Use of Classic OOH</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/70et4JJAP8k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/bObWO-6F24c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Use of Digital OOH</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/EQUu7oTIg-w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/SmXjVV20XgQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Client-Direct Concept</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Ygu-IMFkUuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/HAQtE7aT-lk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Experiential Campaign</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/NsdXoPJiO1w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/SCEmd_x01ho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best International Campaign</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/7KEg7mZscfs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/J9XDBkWYLzQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <h2>2023 Winners by Industry Category</h2>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Innovative Campaign</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Fe6VPQExjXs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/XNrsY9v2YPc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Data-Focused Campaign</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/K-1JYEO6Yis" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/2k39T9lcpb8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best High Impact Campaign</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/rc-bX3CiIQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/58UNGpy2rrQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="awardsWinnerRow">
            <div>
              <h3>Best Community Involvement</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Za_lMkqSGMA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
              <h3>Runner Up</h3>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/t6PjI3Qff3g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="https://images.ctfassets.net/1l6si2vnlb2k/2LqyBaE0VAjfYLUu3sgA24/f0c3611829b81e4f65eb3c172890f5ad/OOH_Awards_-_sponsor_footer__tall___1_.png" /> */}
    </Layout>
  </div>
)

export default Awards



