import React from "react"
import { Link } from "gatsby"

const Hero = () => {
    return (
        <div className="common_banner_section members-zone awardsInnerHero awardsWinners">
            <div className="container">
                <div>
                    <img src="https://images.ctfassets.net/1l6si2vnlb2k/7cY8yI5RPznEmeqWtXFPnR/f63650d7d9dd756f6d3764119da513b2/OOH-white-logo.png" />
                </div>
            </div>
        </div>
    )
}

export default Hero